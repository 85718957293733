<template>
  <document-inputs></document-inputs>
</template>

<script>
import DocumentInputs from "../components/document/DocumentInputs.vue";
export default {
  components: { DocumentInputs }
};
</script>
